var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "col-12 col-md-8",
              staticStyle: { "text-align": "justify" },
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c("v-card-title", [
                    _c("h3", { staticStyle: { "word-break": "normal" } }, [
                      _vm._v(_vm._s(_vm.$t("ServiceNotFound.Title"))),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("section", { staticClass: "text-subtitle-1" }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t("ServiceNotFound.P")))]),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        href: "tel:9729729197",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "#12945f" } },
                                        [_vm._v(" mdi-phone ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("ServiceNotFound.Phone")
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-decoration-underline",
                                        attrs: { href: "tel:9729729197" },
                                      },
                                      [_vm._v("(972) 972-9197")]
                                    ),
                                    _vm._v(". "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        href: "sms:9729729197",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "#12945f" } },
                                        [_vm._v(" mdi-message-text ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("ServiceNotFound.SMS")) +
                                        " "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-decoration-underline",
                                        attrs: { href: "sms:9729729197" },
                                      },
                                      [_vm._v("(972) 972-9197")]
                                    ),
                                    _vm._v(". "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { icon: "" } },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "#12945f" } },
                                        [_vm._v(" mdi-wechat ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticStyle: { "white-space": "normal" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("ServiceNotFound.WebChat")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-0 pt-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 mt-5 text-none",
                                  attrs: { elevation: "0" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backStep()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12 col-md-4" },
            [_c("cart-component")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }